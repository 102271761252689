import React from 'react'
import flag from  '../assets/imgs/flagHp.svg'
import currencyCoinEuro from  '../assets/imgs/currency-coin-euro.svg'
import Delivery from  '../assets/imgs/Delivery.svg'

function Banner() {
  return (
    <>
        <div className="section-banner d-flex justify-content-center">
          <div className="item_banner"> <div className="text_banner" ><img src={flag} alt={flag} className="icon_banner"/> E-commerce français </div></div>
          <div className="item_banner"> <div className="text_banner"><img src={Delivery} alt={Delivery} className="icon_banner"/>Livré en 1 heure</div></div>
          <div className="item_banner"> <div className="text_banner"><img src={currencyCoinEuro} alt={currencyCoinEuro} className="icon_banner"/>Tarif livraison unique 19,90 € HT</div></div>
        </div>
    </>
  )
}

export default Banner