import React, { useEffect, useState } from "react";
import LayoutF from "../../components/LayoutF";
import Services from "../../services/global.service";
import CardProduct from "../../components/cardProduct";
import Next from "../../assets/imgs/keyboard_arrow_right_24px.svg";
import { Link, useLocation } from "react-router-dom";
import FiltreWithSearch from "../../components/filtreWithSearch";
import AllFilterWithSearch from "../../components/allFilterWithSearch";
import FilterOptionsWithSearch from "../../components/filterOptionsWithSearch";

function SearchResult() {
  const [data, setData] = useState([]);
  const [perPage, setPerPage] = useState(10); // Number of items per page
  const perPageOptions = [5, 10, 20, 50];
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const location = useLocation();
  const { searchTerm } = location?.state || {}; // Retrieve the searchTerm from state
  //states for filtre
  const [filterModal, setFilterModal] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [selectedMarques, setSelectedMarques] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [listOption, setListOption] = useState([]);
  const [listMarque, setListMarque] = useState([]);
  const [listCategorie, setListCategorie] = useState([]);
  const [body, setBody] = useState({});
  const [sortingOption, setSortingOption] = useState("");
  const [count, setCount] = useState(0);
  const [openFiltreOptions, setOpenFiltreOption] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [checkedOptions, setCheckedOptions] = useState({});

  const handlePerPageChange = (e) => {
    setPerPage(parseInt(e.target.value));
    setCurrentPage(1); // Reset to first page when changing items per page
  };
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleDeleteMarque = (marqueToDelete) => {
    setSelectedMarques((prevMarques) =>
      prevMarques.filter((marque) => marque?.name !== marqueToDelete)
    );
    setCount(count - 1);
  };
  const handleDeleteOption = (optionName, valueToDelete) => {
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      [optionName]: prevOptions[optionName].filter(
        (value) => value !== valueToDelete
      ),
    }));
    handleValider();
    setCount(count - 1);
  };

  const handleValider = () => {
    let query = `?page=${currentPage}&per_page=${perPage}`;
    // Add search term to query if it exists
    if (searchTerm) {
      query += `&search_term=${searchTerm}`;
    }
    // Add filters to query
    if (Object.keys(selectedOptions).length > 0) {
      Object.entries(selectedOptions).forEach(([key, value]) => {
        if (value.length > 0) {
          query += `&${key}=${value.join(",")}`;
        }
      });
    }
    // Add sorting option to query
    if (sortingOption) {
      query += `&tri=${sortingOption}`
    }
    // Add 'stock' filter
    query += `&stock=${isChecked ? 1 : 0}`;

    // Construct the full URL
    const endpoint = `products/filter-search-frontend${query}`;

    // Fetch data from the API with the constructed URL
    Services.getAll(endpoint)
      .then((res) => {
        if (res?.data?.marques?.length > 1) {
          setListMarque(res?.data?.marques);
        }
        if (res?.data?.categories?.length > 1) {
          setListCategorie(res?.data?.categories);
        }
        setListOption(res?.data?.options);
        setData(res?.data?.products?.data);
        setTotalPages(res?.data?.products?.last_page);
      })
      .catch((err) => {
        console.error("Error fetching products:", err);
      });
  };
  // const getResultFilre = () => {
  //   // Construct the query parameters based on the selected filters and sorting options
  //   let query = `?page=${currentPage}&per_page=${perPage}`;
  //   // Add search term to query if it exists
  //   if (searchTerm) {
  //     query += `&search_term=${searchTerm}`;
  //   }
  //   const endpoint = `products/filter-search-frontend${query}`;

  //   // Fetch data from the API with the constructed URL
  //   Services.getAll(endpoint)
  //     .then((res) => {
  //       // if (res?.data?.marques?.length > 1) {
  //       //   setListMarque(res?.data?.marques);
  //       // }
  //       setListMarque(res?.data?.marques);
  //       setListCategorie(res?.data?.categories);
  //       setListOption(res?.data?.options);
  //       setData(res?.data?.products?.data);
  //       setTotalPages(res?.data?.products?.last_page);
  //     })
  //     .catch((err) => {
  //       console.error("Error fetching products:", err);
  //     });
  // };

  useEffect(() => {
    handleValider();
  }, [
    searchTerm,
    currentPage,
    perPage,
    sortingOption,
    isChecked
  ]);
  return (
    <>
      <LayoutF>
        <div className="bg-auth min-vh-100">
          <div className="container">
            {filterModal && (
              <>
                <AllFilterWithSearch
                  filterModal={filterModal}
                  setFilterModal={setFilterModal}
                  selectedOptions={selectedOptions}
                  setSelectedOptions={setSelectedOptions}
                  selectedMarques={selectedMarques}
                  setSelectedMarques={setSelectedMarques}
                  selectedCategories={selectedCategories}
                  setSelectedCategories={setSelectedCategories}
                  handleValider={handleValider}
                  count={count}
                  setCount={setCount}
                  checkedOptions={checkedOptions}
                  setCheckedOptions={setCheckedOptions}
                  listOption={listOption}
                  listMarque={listMarque}
                  listCategorie={listCategorie}
                />
              </>
            )}
            {openFiltreOptions && (
              <>
                <FilterOptionsWithSearch
                  openFiltreOptions={openFiltreOptions}
                  setOpenFiltreOption={setOpenFiltreOption}
                  selectedMarques={selectedMarques}
                  selectedOptions={selectedOptions}
                  handleDeleteMarque={handleDeleteMarque}
                  handleDeleteOption={handleDeleteOption}
                  setSelectedOptions={setSelectedOptions}
                  setSelectedMarques={setSelectedMarques}
                  setCount={setCount}
                />
              </>
            )}
            {data.length > 0 && (
              <>
                {searchTerm && (
                  <>
                    <div className=" crumb pt-16 text-start">
                      <Link to="/"> Accueil </Link> / Résultat de Recherche /{" "}
                      {searchTerm}
                      <h4 class="bleu800 mt-24">
                        Résultat de Recherche: {searchTerm}{" "}
                      </h4>
                    </div>
                  </>
                )}
                <FiltreWithSearch
                  filterModal={filterModal}
                  setFilterModal={setFilterModal}
                  selectedOptions={selectedOptions}
                  setSelectedOptions={setSelectedOptions}
                  selectedMarques={selectedMarques}
                  setSelectedMarques={setSelectedMarques}
                  handleValider={handleValider}
                  sortingOption={sortingOption}
                  setSortingOption={setSortingOption}
                  count={count}
                  setCount={setCount}
                  setOpenFiltreOption={setOpenFiltreOption}
                  openFiltreOptions={openFiltreOptions}
                  handleDeleteMarque={handleDeleteMarque}
                  handleDeleteOption={handleDeleteOption}
                  isChecked={isChecked}
                  setIsChecked={setIsChecked}
                  listOption={listOption}
                  listMarque={listMarque}
                  listCategorie={listCategorie}
                />
              </>
            )}

            <div className="row mt-32">
              {data &&
                data?.map(
                  (item) => (
                    <>
                      {" "}
                      <CardProduct key={item?.id} data={item} />
                    </>
                  )
                  // )
                )}
              {data && data?.length > 0 ? (
                <div className="row d-flex mb-5 ">
                  <div className="col-6 text-start">
                    <select value={perPage} onChange={handlePerPageChange}>
                      {perPageOptions.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>{" "}
                    éléments par page
                  </div>
                  <div className="col-6 text-end">
                    {" "}
                    <span>
                      {" "}
                      {currentPage} / {totalPages}
                    </span>
                    <button
                      onClick={handlePrevPage}
                      className={`link-s closed ${
                        currentPage === 1 ? "" : "link-pag"
                      }`}
                      disabled={currentPage === 1}
                    >
                      <img src={Next} alt="icon pagination" />
                    </button>
                    <button
                      onClick={handleNextPage}
                      className={`link-s ${
                        currentPage === totalPages ? "" : "link-pag"
                      }`}
                      disabled={currentPage === totalPages}
                    >
                      <img src={Next} alt="icon pagination" />
                    </button>
                  </div>
                </div>
              ) : data?.length == 0 ? (
                <h5>Aucun produit ne correspond à votre recherche</h5>
              ) : null}
            </div>
          </div>
        </div>
      </LayoutF>
    </>
  );
}

export default SearchResult;
